// 动物多样性监测
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {

            columnsData: [{
                label: "拍摄时间",
                prop: "PSSJ",
                minWidth: 140
            }, {
                label: "中文名",
                prop: "MC_ZW"
            }, {
                label: "拉丁名",
                prop: "MC_LD"
            }, {
                label: "物种类型",
                prop: "WZLX"
            }, {
                label: "物种分布（相机布设点位）",
                prop: "SBMC",
                minWidth: 150
            }, {
                label: "种群密度",
                prop: "ZQMD"
            },
            {
                label: "动物行为",
                prop: "DWXW"
            }, {
                label: "俗名",
                prop: "MC_S"
            }, {
                label: "动物数量",
                prop: "DWSL"
            }, {
                label: "生境（植被、海拔）",
                prop: "SJ",
                minWidth: 150
            },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;